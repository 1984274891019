// Colors:

$primary-100: #154B2B;
$primary-200: #186037;
$primary-300: #47A16D;
$primary-400: #A5D1B1;
$primary-500: #C8E4CF;
$primary-600: #ECF6EF;
$secondary-100: #C0951B;
$secondary-200: #D3A416;
$secondary-300: #F1C26E;
$secondary-400: #FCF2E3;
$dark-100: #000000;
$dark-200: #444444;
$dark-300: #808080;
$dark-400: #A5A5A5;
$dark-500: #D9D9D9; // Figmaban 600-as, ne ezt hasznaljuk.
$dark-600: #D9D9D9;
$light-100: #FFFFFF;
$light-200: #FAFAFA;
$light-300: #EEEEEE;
$light-400: #E4E4E4;
$info-100: #5898CC;
$info-200: #BEDCF4;
$info-300: #E6F0F9;
$info-300: #E6F0F9;
$state-100: #E44E4E;
$state-200: rgba(228, 78, 78, 0.89);
$state-300: #e09797;

$player-A: #D3A416;
$player-Bplus:#186037;        //#47A16D; //#A5D1B1 volt
$player-B: #5898CC;
$player-C: #A5A5A5;
$player-D: #E44E4E;
$player-X: #F1C26E;
$player-P: #F97B06;
$player-none: #E4E4E4;

$position-1: #d32828;
$position-2: #eb6060;
$position-3: #ff9090;

// Media query:
$sm: 576px;
$md: 767.98px;
$lg: 991.98px;
$tablet: 1199.98px;
$xl: 1399.98px;
$xxl: 1399.98px;
$xxxl: 1699.98px;

$x1: #F1C26E;
$x2: #47A16D;
$x3: #82cdff;
$x4: #C8E4CF;
$x5: #ECF6EF;
$x6: #4b9565;
