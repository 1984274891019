@import 'src/styles/variables';

.contentBox {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    padding: 20px 15px 15px 15px;
    background-color: $light-200;
    flex-direction: row;
    min-height: calc(100% - 60px);

    @media (max-width: $xl) {
        flex-direction: column;
        gap: 24px;
        overflow: auto;
    }

    .outletContainer {
        position: relative;
        flex-grow: 1;
    }

}
