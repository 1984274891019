@import 'src/styles/variables';

.report {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-grow: 0;

    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .headerTitle {
            margin: 0;
            color: $dark-100;
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .headerActions {
            display: flex;
            gap: 16px;

            .actionBtn {
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &List {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-right: 24px;

        .row {
            display: grid;
            grid-template-columns: 16fr 16fr 10fr 10fr;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $dark-100;

            &.tableLine {
                position: sticky;
                top: 0;
                background-color: $light-100;
            }

            .label {
                color: $dark-100;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                white-space: nowrap;
            }

            .reportTableDate:not(.label) {
                font-weight: 600;
            }

            .reportTableAction {
                display: flex;
                justify-content: flex-start;
                gap: 20px;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
