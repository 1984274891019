@import 'src/styles/variables';

.playerCardBox {
    background-color: $light-100;
    display: flex;
    padding: 8px 24px;
    border-radius: 4px;
    border: 1px solid $light-400;
    gap: 15px;

    //grid-template-rows: min-content 64px 1fr 1fr 1fr 1fr 1fr 64px 1fr;

    &.selected {
        background-color: $primary-500;
    }

    .checkBox,
    .imageBox,
    .nameBox,
    .yearBox,
    .teamBox,
    .postBox,
    .minutesBox,
    .chartBox,
    .actionBox {
        flex-basis: 10%;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .checkBox {
        flex-basis: 16px;
    }

    .imageBox {
        flex-basis: 64px;
        @import 'src/styles/playerimage';

        img {
            width: 58px;
            height: 58px;

            &.category {
                border: 5px solid black;

                @import 'src/styles/category';
            }
        }
    }

    .nameBox {
        flex-basis: 120px;
        justify-content: flex-start;

        span {
            color: $primary-200;
            cursor: pointer;
            font-weight: 600;
        }
    }

    .yearBox {
        flex-basis: 100px;
    }

    .postBox {
        flex-basis: 60px;
    }

    .chartContainer {
        flex-basis: 64px;
        height: 64px;
        width: 64px;
        flex-shrink: 0;

        svg {
            margin-left: -7.5px;
            margin-top: -7.5px;
        }
    }

    .teamBox {
        flex-basis: 150px;
        flex-grow: 1;
        justify-content: flex-start;
        display: flex;
        gap: 4px;

        img {
            height: 19px;
            width: auto;

        }
    }

    .postBox,
    .minutesBox {
        justify-content: flex-start;
    }

    .actionBox {
        flex-basis: 75px;

        @media (max-width: $tablet) {
            flex-basis: 40px;
        }
    }

    .addToShortlist {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $primary-300;
        border-radius: 4px;
        height: 31px;
        color: $primary-300;
        background-color: $light-100;
        gap: 8px;
        cursor: pointer;
        white-space: nowrap;

        @media (max-width: $tablet) {
            border: none;
        }

        &:active {
            transform: scale(0.98);
        }

        p {
            font-size: 13px;

            @media (max-width: $tablet) {
                display: none;
            }
        }

        .addIcon {
            filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
        }
    }
}
