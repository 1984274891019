@import '../../../styles/variables';

.tabBox {
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .itemValue {
        color: $state-100;
        text-transform: lowercase;
    }

    .tabBoxButton {
        height: 40px;
        width: 20%;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        line-height: 40px;
        color: $light-100;
        background: {
            image: linear-gradient(180deg, #52B87D 27%, #47A16D 100%);
        };
        position: relative;

        &.disabled {
            background: {
                image: linear-gradient(180deg, $primary-400 27%, $primary-500 100%);
            };
            color: white;
            pointer-events: none;
        }

        &:not(:last-child):not(:has(+ .active)):not(.active) {

            &:after {
                content: '';
                width: 1px;
                height: 40px;
                background-color: $light-100;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &:hover {
            background: {
                image: unset;
                color: $primary-500;
            };
            color: $primary-300;
        }

        &:first-child {
            border-top-left-radius: 4px;
        }
        &:last-child {
            border-top-right-radius: 4px;
        }


        &.active {
            background-color: $light-100;
            background: {
                image: unset;
            };
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 700;
            color: $primary-100;
            text-transform: uppercase;
        }
    }

    .playerAlerts {

        &::after {
            content: "";
            position: absolute;
            right: 15px;
            top: 17.5px;
            border-top: 5px solid $light-100;
            border-bottom: 0 none transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            width: 0;
            height: 0;
        }

        &:hover {
            cursor: pointer;
        }

        &:hover,
        &:focus,
        &:active {
            .dropDownMenu {
                display: flex;
            }
        }

        .dropDownContainer {
            position: absolute;
            width: 100%;
            top: 0px;
            padding-top: 50px;
        }

        .dropDownMenu {
            position: relative;
            display: none;
            flex-direction: column;
            border: 1px solid $primary-300;
            border-radius: 4px;
            background-color: $light-100;
            z-index: 100;
            overflow: hidden;

            .dropDownItem {
                text-decoration: none;
                color: $primary-300;

                &:hover {
                    background-color: $primary-600;
                }

                &.disabled {
                    color: $dark-100;
                    cursor: default;

                    &:hover {
                        background-color: inherit;
                    }
                }
            }
        }
    }
}

.h100p {
    // height: 100%;
    height: calc(100% - 40px);
    box-shadow: 0 0 40px 0 rgba(21, 75, 43, 0.10);
    border-radius: 4px;
    overflow: hidden;
    background-color: $light-100;

    // @media (max-width: $xl) {
    //   height: 75vh;
    // }
}
