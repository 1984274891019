@import 'src/styles/variables';

.configList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    width: 100%;
    min-height: 300px;
    padding: 23px 24px;
    background-color: $light-200;

    .configRecord {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        border: 1px solid $light-400;
        border-radius: 4px;
        padding-left: 24px;
        width: 100%;
        height: 60px;
        overflow-x: hidden;
        background-color: $light-100;
        font-family: 'Open Sans';

        .configLabel {
            flex-basis: 100%;
        }

        .actionLabel {
            flex-basis: 30%;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            gap: 8px;

            button {
                width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.header {
            border: 0 none transparent;
            background-color: transparent;
            height: 40px;

            .configLabel,
            .actionLabel {
                color: $dark-400;
                text-transform: uppercase;
            }
        }
    }

    .backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 100%;
        background-color: rgba(#fff, 0.75);
        backdrop-filter: grayscale(1);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: all 250ms;
        overflow: hidden;

        &.visible {
            left: 0;
            padding-right: 1px;
        }
    }

    .modification {
        display: flex;
        align-items: flex-end;
        gap: 8px;

        .textInput {
            padding: 1px 5px;
            width: 200px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid $dark-400;
        }

        button {
            width: calc(124px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
