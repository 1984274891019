@import 'src/styles/variables';

.column {
    height: 100%;
    flex-basis: calc(50% - 12px);
    max-width: calc(50% - 12px);
    max-height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .content {
        border-radius: 4px;
        border: 1px solid $primary-500;
        background: $light-100;
        padding: 24px;
        flex-shrink: 1;

        &.report {
            flex-grow: 0;
        }

        &.baseData {
            flex-basis: auto;
        }

        &.chartData {
            flex-basis: auto;

            .roseChart {
                height: 20vw;
                min-height: 20vw;

                @media (max-width: $xl) {
                    height: 36vw;
                    min-height: 36vw;
                }
            }
        }

        &.fullFill {
            padding: 0;
        }
    }
}

.seasons {

    .statList {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .row {
        display: flex;
        gap: 5px;

        &.separated {
            border-top: 1px solid $dark-500;
            padding-top: 15px;
        }
    }

    .label {
        color: $dark-100;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
    }

    .statCompetitionName {
        flex-basis: 32%;
    }

    .statMatchNumber {
        flex-basis: 17%;
    }

    .statGoalNumber {
        flex-basis: 17%;
    }

    .statGoalPassNumber {
        flex-basis: 17%;
    }

    .statMinutesPlayed {
        flex-basis: 17%;
    }

    .statMatchNumber,
    .statGoalNumber,
    .statGoalPassNumber,
    .statMinutesPlayed {
        text-align: center;
    }

    .tableLine {
        .label {
            text-transform: uppercase;
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: $dark-100;

            &.statCompetitionName {
                text-align: left;
            }
        }
    }
}

.stats {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &Header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        .indicatorBox {
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
        }

        .traits {
            flex-shrink: 0;
            display: flex;
            gap: 10px;
        }

        .trait {
            border: 1px solid $light-400;
            border-radius: 4px;
            padding: 8px;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 6.5px;

            .label {
                color: $dark-100;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: 600;
            }

            .value {
                font-size: 14px;
            }
        }
    }

    .chartField {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 250px;
        width: 100%;
        flex-shrink: 1;

        @media (max-width: $xl) {
            flex-wrap: wrap;
        }

        .roseChart {
            flex: 1 1 50%;
            height: 100%;

            @media (max-width: $xxxl) {
                flex: 1 1 70%;
            }

            @media (max-width: $xl) {
                flex: 1 1 100%;
            }
        }

        .field {
            display: flex;
            justify-content: center;
            flex: 1 1 50%;

            @media (max-width: $xxxl) {
                flex: 1 1 30%;
            }

            @media (max-width: $xl) {
                flex: 1 1 100%;
            }
        }
    }
}

.additionalData {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    border: 1px solid $primary-500;
    border-radius: 4px;
    padding: 10px;
    background-color: $light-200 !important;

    h3 {
        margin: 0 0 10px 0;
        color: $dark-100;
        text-transform: uppercase;

        font: {
            size: 12px;
            weight: 600;
        }
    }


    .strength {
        flex: 1 1 50%;
        border-right: 1px solid $primary-500;
        padding: 0 15px 0 0;
        text-align: right;

        img {
            padding: 0 0 0 8px;
        }
    }

    .weakness {
        flex: 1 1 50%;
        position: relative;
        left: -1px;
        border-left: 1px solid $primary-500;
        padding: 0 0 0 15px;
        text-align: left;

        img {
            padding: 0 8px 0 0;
        }
    }

    .swItem {
        margin: 5px 0;
        white-space: nowrap;

        span {
            display: inline-block;
            max-width: 215px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
        }
    }
}
