@import 'src/styles/variables';

.selectBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .selectLabel {
        color: $dark-100;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
}

.selectWrapper {
    position: relative;
    border: 1px solid $light-400;
    border-radius: 6px;
    background-color: $light-100;
    height: 40px;

    .selectButton {
        position: relative;
        min-width: 200px;
        height: 100%;
        border-radius: 5px;
        background: {
            image: url('../../../assets/images/icons/icon_arrow_down.svg');
            repeat: no-repeat;
            size: 9.5px auto;
            position: center right 10px;
            color: $light-100;
        };
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        justify-content: center;

        &.opened {
            z-index: 10;
        }

        &:hover:not(.disabled) {
            background-color: $light-400;
            cursor: pointer;
        }

        &.disabled {
            // opacity: 0.3;
        }

        .defaultElement {
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 90%;
            color: $dark-100;
            font-family: 'Open Sans', sans-serif;
        }

        .selectedElement {
            display: inline-block;
            margin: 0 5px;
        }

        .emptySelection {
            position: absolute;
            right: 28px;
            font-weight: bold;

            &:hover {
                color: $state-100;
            }
        }
    }

    &.focus {
        .selectButton {
            &::after {
                transform: rotate(90deg);
            }
        }

        .selectOptions {
            display: block;
        }
    }

    &.selected {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.selectBackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    display: block;
    background: transparent; // rgba(0, 0, 0, 0.3);

    .selectOptions {
        position: absolute;
        z-index: 2001;
        max-height: 36px * 8;
        overflow-y: auto;
        border-bottom: 1px solid $light-400;
        border-right: 1px solid $light-400;
        border-left: 1px solid $light-400;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .filterContainer {
            padding: 3px 20px;
            background-color: $light-100;

            .filterInput {
                padding: 4px;
                border: 1px solid $primary-300;
                width: 100%;
                outline: 0 none transparent;
            }
        }

        .selectOption {
            padding: 3px 20px;
            background-color: $light-100;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover,
            &.preselected {
                background-color: $primary-200;
                color: $light-100;
                cursor: pointer;
            }

            &.selected {
                cursor: default;
                color: $light-100;
                background: {
                    // image: url('../../../../assets/images/XFB_cube_success_icon_white.svg');
                    repeat: no-repeat;
                    position: center right 15px;
                    color: $primary-300;
                };
            }
        }
    }
}

.thin {
    .selectButton {
        min-width: 100px;
    }
}
