@import 'src/styles/variables';

.profile {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.infoRow {
    gap: 24px;
}

.infoRow,
.playerRow {
    display: flex;

    .sector {
        flex-basis: 50%;
    }

    .infoList {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .infoRecord {
            display: flex;
            gap: 6px;
            align-items: center;

            span {
                flex-basis: 50%;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                line-height: normal;
            }

            .label {
                color: $dark-100;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
            }

            .value {
                white-space: nowrap;
                color: $dark-100;
                font-size: 14px;
                font-weight: 600;

                &.teamSearch {
                    cursor: pointer;
                }
            }
        }
    }

    .sectorPlayer {
        display: flex;
        gap: 18px;
        flex-basis: 430px;
        border-right: 1px dotted $primary-400;
        max-width: calc(100% - 88px);
        padding-right: 18px;

        @import 'src/styles/playerimage';

        img {
            width: 100px;
            height: 100px;
            border: 6px solid $light-400;
            flex-shrink: 0;
            @import 'src/styles/category';
        }

        .playerName {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 4px;
            overflow: hidden;

            .first,
            .last {
                font-size: 16px;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $primary-200;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }

            .last {
                font-weight: 700;
                font-size: 20px;
                text-transform: uppercase;
            }
        }
    }

    .sectorPoint {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .label {
            color: $dark-100;
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            line-height: normal;
        }

        .dottedCircle {
            border: 1px dotted $secondary-200;
            width: 62px;
            height: 62px;
            border-radius: 50%;
            padding: 3px;

            .valueCircle {
                width: 100%;
                height: 100%;
                border: 2px solid $secondary-200;
                border-radius: 50%;
                box-shadow: 0 0 20px 0 rgba($secondary-200, 0.50);
                background-color: $light-100;
                display: flex;
                justify-content: center;
                align-items: center;

                .indexValue {
                    color: $secondary-200;
                    font-size: 20px;
                    font-family: 'Montserrat', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: uppercase;
                }
            }
        }
    }
}
