@import 'src/styles/variables';

.Choice {
  &Box {
    height: 24px;
    width: 194px;
    //padding: 4px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $primary-500;
    background: $primary-600;

    &.small {
      width: 120px;
      box-shadow: 2px 2px 5px -2px rgba(21, 75, 43, 0.42);
    }

    .choiceButton {
      width: 50%;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: $primary-300;
      border-radius: 12px;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

    //   &[disabled] {
    //     filter: grayscale(100%);
    //   }

      &:focus-visible {
        outline: 1px solid $primary-500;
        background-color: $primary-500;
      }

      &.selected {
        outline: 1px solid $primary-300;
        background-color: $primary-300;
        color: $light-300;

        &:focus-visible {
          outline: 2px solid $primary-200;
        }
      }
    }
  }
}
