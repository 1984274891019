@import '../../styles/variables';

.notFound {
    height: 100%;
    border-radius: 10px;
    // background-color: $medium-green;

    h2 {
        padding-top: 30px;
        text-align: center;
    }

    .link {
        text-align: center;
        font-size: 20px;

        a {
            text-decoration: none;
        }
    }
}
