@import 'src/styles/variables';

.backDrop {
    // a térkép területek 500-környékén eltűnnek, de a jobb alsó és bal felső sarokban a layerek csak 1000-nél
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.40);
    display: flex;
    align-items: center;
    justify-content: center;

    .modalBox {
        min-width: 30%;
        max-width: 90%;
        // min-height: 25%;
        max-height: 90%;
        background-color: $light-100;
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid $primary-500;

        // @media (max-width: $xxxl) {
        //     width: 70%;
        // }

        // @media (max-width: $xl) {
        //     width: 80%;
        // }

        // @media (max-width: 1279px) {
        //     width: 90%;
        // }

        &Header {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: relative;
            width: 100%;

            .close {
                position: absolute;
                top: 0;
                right: 5px;
                transform: translateX(50%);

                img {
                    width: 32px;
                    height: 32px;
                }
            }

        }

        &Content {
            height: 100%;
            overflow: hidden;
        }

        &Text {
            padding: 30px 0;
        }

        &Button {
            margin-left: 20px;
            width: 120px;
            justify-content: center;
        }

        .buttonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
