@import 'src/styles/variables';

.contentBox {
    display: flex;
    gap: 32px;
    height: 100%;
    z-index: 0;

    @media (max-width: $xl) {
        gap: 12px;
    }

    .selectedPlayers {
        height: 100%;
        width: 440px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        //border: 1px solid seagreen;
        //flex-shrink: 0;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid $primary-500;
        //background: $light-100;
        background: $light-200;

        @media (max-width: $xl) {
            width: 40%;
            padding: 24px 12px 12px 12px;
        }

        .scrollbarContainer {
            width: 390px;
            height: calc(100% - 60px);

            @media (max-width: $xl) {
                width: 100%;
            }

            .players {
                display: flex;
                gap: 12px;
                flex-direction: column;
                width: 100%;

                .placeholderWrapper {
                    background-color: $primary-500 !important;
                }

                .playerPlaceholder {
                    position: relative;
                    z-index: -1;
                    border-radius: 4px;
                    border: 1px dashed $primary-400;
                    background-color: $primary-600;
                    height: 138px;
                    width: 100%;
                    color: $primary-300;
                    display: flex;
                    gap: 5.5px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }

            [aria-roledescription="sortable"] {
                position: relative;
            }
        }
    }

    .playerSelector {
        flex-basis: 100%;
        width: 825px;
        height: 100%;
        border-radius: 4px;
        border: 1px solid $primary-500;
        //border: 1px solid salmon;

        @media (max-width: $xl) {
            width: 60%;
        }
    }
}

.shortListContainer {
    position: relative;
    height: 100%;

    .filters {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        .select {
            flex: 0 0 20%;
        }

        .search {
            flex: 0 0 20%;
            border: 1px solid $light-400;
            border-radius: 6px;
            background-color: $light-100;
            height: 40px;
            padding: 0 10px;
        }
    }


    &.empty {
        background: {
            image: url('../../../../assets/images/football_field.svg');
            repeat: no-repeat;
            position: center;
            size: 20%;
        };

        .emptyStateText {
            position: relative;
            top: 65%;
            display: block;
            color: $dark-600;
            font-family: 'Open Sans', sans-serif;
            font-size: 13px;
            text-align: center;
        }
    }

    &.listed {
        background-image: none;
        padding: 5px 24px 24px 24px;

        @media (max-width: $xl) {
            padding: 5px 0 34px 12px;
        }

        .emptyStateText {
            display: none;
        }

        .listHeader {
            display: flex;
            align-items: center;
            width: 100%;
            height: 35px;
            padding-left: 24px;
            gap: 16px;
            font-family: 'Open Sans';

            .playerPicture {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 64px;
                box-sizing: border-box;
                overflow: hidden;
            }

            .playerName {
                width: 30%;
            }

            .birthYear {
                width: 15%;
            }

            .teamName {
                width: 20%;
            }

            .post {
                width: 20%;
                text-align: center;
            }

            .shadowTeam {
                width: 15%;
                text-align: center;
            }
        }

        .listHeader {
            color: $dark-100;
            text-transform: uppercase;
            padding-right: 15px;

            .playerName,
            .birthYear,
            .teamName,
            .post,
            .minutes,
            .classification,
            .date,
            .fradiIndex,
            .shadowTeam {
                font-size: 0.8rem;
            }
        }

        .list {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            padding-right: 15px;

            [aria-roledescription="draggable"] {
                position: relative;
            }

            .cardContainer {
                touch-action: manipulation;
                cursor: grab;

                &:active {
                    cursor: grabbing;
                }
            }

            .drag {
                position: absolute;
                left: 0;
                top: 0;
                width: 15px;
                height: 100%;
                background: {
                    color: $primary-400;
                    size: 5px 5px;
                    repeat: repeat;
                }

            }

            .noResult {
                display: block;
                margin-top: 100px;
                text-align: center;
            }

            .waiting {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 1000;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(7px);
            }
        }
    }
}

.drag {
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 100%;
    background: {
        color: $primary-400;
        size: 5px 5px;
        repeat: repeat;
    }
    cursor: grab;

    // &:active {
    //     cursor: grabbing;
    // }
}
