@import 'src/styles/variables';

.Rating {
    &Box {
        width: 100%;
        height: 58px;
        padding: 8px 16px;
        border-radius: 4px;
        border: 1px solid $primary-500;
        background: $light-100;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &Header {
        display: flex;
        gap: 10px;
        height: 18px;
    }

    &Title {
        color: $dark-100;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-family: 'Open Sans', sans-serif;
    }

    &Value {
        color: $dark-100;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: 'Open Sans', sans-serif;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $light-300;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    &Line {
        width: 100%;
        display: flex;
        gap: 5px;

        &.disabled {
                pointer-events: none;
                filter: grayscale(100%);

                .NotRated img {
                    opacity: 0.5;
                }
        }

        .NotRated img {
            //filter: contrast(0);
            filter: brightness(0) invert(83%) sepia(7%) saturate(15%) hue-rotate(349deg) brightness(93%) contrast(84%);
            opacity: 0.4;
        }
    }
}

.greenBalls {
    filter: none !important;
}
