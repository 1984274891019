@import 'src/styles/variables';

.column {
    //height: 100%;
    flex-basis: calc(50% - 12px);
    max-width: calc(50% - 12px);
    max-height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .content {
        border-radius: 4px;
        border: 1px solid $primary-500;
        background: $light-100;
        padding: 24px;
        //flex-grow: 1;
        flex-shrink: 1;

        &.playerProfile {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        &.otherSettings {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .line {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 24px;

                &Label {
                    text-align: end;
                    width: 245px;
                }
            }
        }

        &.comment {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .commentTitle {
                color: $dark-100;
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .commentField {
                width: calc(100% - 16px);
                min-height: 80px;
                border-radius: 4px;
                border: 1px solid $light-400;
                color: $dark-100;
                background: $light-200;
                font-size: 15px;
                font-weight: 500;
                font-family: 'Open Sans', sans-serif;
                padding: 8px;
                outline: none;
                opacity: 1!important;

                &:active,
                &:focus {
                    border: 1px solid $primary-500;
                    background-color: $primary-600;
                }
            }

            .commentBox {
                width: 100%;

                .counter {
                    width: 100%;
                    text-align: end;
                    font-size: 12px;
                    color: $dark-300;
                }
            }
        }

        .selectors {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex-basis: 40%;
            gap: 10px;

            @media (max-width: $xl) {
                width: 100%;
            }
        }

        .sideBySide {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            padding-top: 20px;

            @media (max-width: $xl) {
                flex-direction: column;
            }
        }

        .loader {
            position: relative;
            width: 100%;
        }

        .chartContainer {
            width: 320px;
            height: 340px;
            margin-top: 10px;
            margin-bottom: -30px;

            @media (max-width: $xl) {
                align-self: center;
            }
        }

        &.fullFill {
            padding: 0;
        }
    }
}

.row {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.indicatorBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 10px;

    @media (max-width: $xl) {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }

    .indicator {
        position: relative;

        .info {
            display: inline-block;
            position: absolute;
            right: 10px;
            top: 5px;
            border: 1px solid $primary-200;
            border-radius: 8px;
            padding: 0 6px 4px 6px;
            width: 4px;
            height: 12px;
            color: $primary-200;
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.actions {
    display: flex;
    gap: 10px;
}

.progressBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.emptyContainer {
    min-height: 100px;
    border-radius: 4px;
    border: 1px dashed $dark-600;
    background: $light-100;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-500, #BBB);
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile {
    display: flex;
    height: 101px;
    gap: 18px;

    @import 'src/styles/playerimage';

    .profileImg {
        width: 101px;
        height: 101px;
        flex-shrink: 0;
        border: 6px solid $light-300;

        @import 'src/styles/category';
    }

    &Data {
        flex-basis: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .profileName {
        display: flex;
        color: $primary-200;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        line-height: normal;

        .nameContiner {
            width: 40%;
            display: flex;
            flex-direction: column;
            border-right: 1px dotted $primary-400;

            .first {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }

            .last {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 20px;
                line-height: 27px;
            }
        }

        .sectorPoint {
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .label {
                color: $dark-100;
                font-size: 14px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                line-height: normal;
            }

            .dottedCircle {
                border: 1px dotted $secondary-200;
                width: 62px;
                height: 62px;
                border-radius: 50%;
                padding: 3px;

                .valueCircle {
                    width: 100%;
                    height: 100%;
                    border: 2px solid $secondary-200;
                    border-radius: 50%;
                    box-shadow: 0 0 20px 0 rgba($secondary-200, 0.50);
                    background-color: $light-100;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .indexValue {
                        color: $secondary-200;
                        font-size: 20px;
                        font-family: 'Montserrat', sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .reporterContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 30%;
            border-left: 1px dotted $primary-400;
            padding-left: 20px;
            gap: 10px;

            .reporterText,
            .userName {
                font-size: 14px;
                margin: 0;
            }

            .reporterText {
                text-transform: capitalize;
                color: $primary-100;
            }

            .userName {
                font-weight: 600;
                color: $dark-100;
            }

        }

    }

    &Details {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        gap: 16px;


        .details {
            display: flex;
            flex-direction: column;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            line-height: normal;
            gap: 6px;

            &Label {
                color: $dark-100;
                font-size: 10px;
                line-height: 14px;
                font-weight: 600;
                text-transform: uppercase;
            }

            &Value {
                font-size: 14px;
                line-height: 16px;
                font-weight: 600;
                color: $dark-100;
                display: flex;
                gap: 4px;
                align-items: center;

                &.clickable {
                    cursor: pointer;
                }
            }

            img {
                height: 14px;
                width: auto;
            }
        }
    }
}
