@import 'src/styles/variables';

.toggleList {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary-300;
    border-radius: 4px;
    height: 31px;
    color: $primary-300;
    background-color: $light-100;
    gap: 8px;
    cursor: pointer;
    white-space: nowrap;

    &[disabled] {
        color: $dark-400 !important;
        border-color: $dark-600;
    }

    @media (max-width: $tablet) {
        border: none;
    }

    &:active {
        transform: scale(0.98);
    }

    &.inList {
        background-color: $primary-300;
        color: white;

        img {
            filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(136deg) brightness(103%) contrast(101%);
        }
    }

    &.loading {
        color: $primary-300;
        background-image: linear-gradient(90deg, white 0%, white 25%, $primary-500 50%, white 75%, white 100%);
        background-size: 200% 100%;
        animation: 0.7s linear 0s infinite running loading;

        img {
            filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
        }
    }
    @keyframes loading {
        0% {
            background-position: 200%;
        }
        100% {
            background-position: 0%;
        }
    }

    p {
        font-size: 13px;

        @media (max-width: $tablet) {
            display: none;
        }
    }

    .addIcon,
    .playerIcon {
        filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
    }

    .playerIcon {
        height: 20px;
        width: 20px;
    }
}

.configList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    width: 100%;
    min-height: 300px;
    padding: 23px 24px;
    background-color: $light-200;

    .filterPageButton {
        align-self: flex-end;
    }

    .configRecord {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        border: 1px solid $light-400;
        border-radius: 4px;
        padding-left: 24px;
        width: 100%;
        height: 60px;
        overflow-x: hidden;
        background-color: $light-100;
        font-family: 'Open Sans';

        .configLabel {
            flex: 2 2 100%;
        }

        .actionLabel {
            flex: 1 0 30%;
            display: flex;
            align-items: center;
            gap: 8px;

            button {
                // width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.header {
            border: 0 none transparent;
            background-color: transparent;
            height: 40px;

            .configLabel,
            .actionLabel {
                color: $dark-400;
                text-transform: uppercase;
            }
        }
    }
}

.saveAsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .saveAsInput {
        padding: 1px 5px;
        width: 200px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid $dark-400;
    }
}
