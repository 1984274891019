@import 'src/styles/variables';

.pageLayerBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: calc(100vh - 80px);
    background-color: $light-100;
    z-index: 1000;
    padding: 20px 24px;
    border-radius: 4px;
    box-shadow: 0 0 40px 0 rgba(21, 75, 43, 0.10);
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.normalView {
        position: unset;
        top: unset;
        left: unset;
        right: unset;
    }
}

.sample {
    border: 1px solid $primary-100;
}

.linkify {
    color: $primary-300;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;

    img {
        margin-right: 6px;
        width: 16px;
        height: 16px;
    }
}

.pageLayerHeader {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerTitle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 22px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.details {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    gap: 24px;
    height: calc(100% - 50px);
    flex-wrap: wrap;
}

.tallButton {
    padding: 10px 16px;
    height: 37px !important;
}
