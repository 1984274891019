@import 'src/styles/variables';

.positionPopup {
    width: 140px;
    // max-height: 130px;
    position: relative;
    box-shadow: 0 0 20px 0 rgba(21, 75, 43, 0.20);
}

.playerRibbon {
    outline: unset;
    border: unset;
    cursor: pointer;
    height: 20px;
    width: 100%;
    padding: 4px;
    background-color: rgba($light-100, 0.8);
    display: flex;
    align-items: center;
    gap: 8px;

    @import 'src/styles/playerimage';

    .playerImage {
        width: 30px;
        height: 30px;
        border: 2px solid grey;

        @import 'src/styles/category';
    }

    .infoBar {
        display: flex;
        gap: 4px;
        align-items: center;

        .info {
            color: $dark-300;
            //font-family: 'Open-Sans', sans-serif;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }

        .nameBox {
            display: flex;
            gap: 4px;
            padding-left: 3px;
        }
    }

    .name {
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        color: $primary-200;
        font-weight: 500;
        font-style: normal;
        line-height: 16px;

        &.bold {
            max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 700;
        }
    }


    &:not(:last-child) {
        border-bottom: 1px dotted $primary-400;
    }
}

.bottomLine {
    height: 3px;
    width: 100%;
    border-top: 3px solid $primary-300;
    overflow: hidden;

    .string {
        position: absolute;
        left: 50%;
        top: auto;
        width: 0;
        height: 0;
        border-left: 1px solid $primary-300;
        transform: translateY(-10px);
    }

    &.below .string {
        top: 0;
    }

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: 50%;
        top: auto;
        border: 10px solid transparent;
        border-top-color: $primary-300;
        transform: translate(-50%, -1%);
    }

    &.below::before {
        top: -20px;
        border-top-color: transparent;
        border-bottom-color: $primary-300;
    }
}
