@import '../../../styles/variables';

.playerFilter {
    min-height: 938px;

    h3 {
        margin: 0 20px;
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 20px;
    }

    .filterPageButton {
        &[disabled] {
            color: $dark-400 !important;
        }

        &.loading {
            color: $primary-300;
            background-image: linear-gradient(90deg, white 0%, white 25%, $secondary-300 50%, white 75%, white 100%);
            background-size: 200% 100%;
            animation: 0.7s linear 0s infinite running loading;

            img {
                filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
            }
        }
        @keyframes loading {
            0% {
                background-position: 200%;
            }
            100% {
                background-position: 0%;
            }
        }
    }

    .playerFilterContent {
        position: relative;
        display: flex;
        height: calc(100% - 80px);
        gap: 23px;
        //overflow-y: scroll;

        .filterContainer {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 60%;
            flex-grow: 1;
            gap: 15px;
            overflow: auto;

            .filterSettings {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            .tabContainer {
                border: 1px solid $primary-500;
                border-radius: 4px;
                background-color: $light-200;
                flex-grow: 1;
                overflow: hidden;

                .sliders {
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                    row-gap: 24px;
                    column-gap: 24px;
                }

                .content {
                    height: 100%;

                    &.empty {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        //background: {
                        //    image: url('../../../assets/images/football_field.svg');
                        //    repeat: no-repeat;
                        //    position: center;
                        //    size: 15%;
                        //};
                        //
                        .emptyStateText {
                            position: relative;
                            display: block;
                            color: $dark-300;
                            font-family: 'Open Sans', sans-serif;
                            font-size: 13px;
                            text-align: center;
                        }
                    }
                }
            }

            .postAndProfile {
                display: flex;
                gap: 24px;
                padding-bottom: 25px;
                border-bottom: 1px dotted $primary-400;

                .selectButton {
                    height: 48px;
                }
            }


            .otherFilters {
                display: flex;
                flex-wrap: wrap;
                gap: 24px;
                padding-top: 24px;

                .filterDiv {
                    display: flex;
                    width: 100%;
                    gap: 23px;

                    .categorySelect {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        gap: 12px;

                        flex-basis: 100%;
                    }
                }
            }

            .bottomButtons {
                flex-grow: 1;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }

        .playerList {
            width: 39%;
            height: auto;
            max-height: 820px;
            border: 1px solid $primary-500;
            border-radius: 4px;
            background-color: $light-200;
            position: relative;

            &.empty {
                background: {
                    image: url('../../../assets/images/state_player.svg');
                    repeat: no-repeat;
                    position: center;
                    size: 20%;
                };

                .emptyStateText {
                    position: relative;
                    top: 67%;
                    color: $dark-300;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 13px;
                    text-align: center;

                    @media (max-width: $xxl) {
                        top: 70%;
                    }
                }

                .listContainer {
                    display: none;
                }
            }

            &.listed {

                .listContainer {
                    display: grid;
                    padding: 10px;
                    row-gap: 10px;

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.dropdownToggle {
    position: relative;
    padding-right: 20px !important;

    &::after {
        content: '';
        position: absolute;
        right: 5px;
        top: 15px;
        border-top: 5px solid $secondary-100;
        border-bottom: 0 none transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        width: 0;
        height: 0;
    }

    .dropdownMenu {
        position: absolute;
        left: 0;
        top: 40px;
        z-index: 1;
        border: 1px solid black;
        background-color: #eeeeee;

        .dropdownItem {
            display: block;
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #cccccc;
            }

            &.disabled {
                color: #888888;
                pointer-events: none;
                cursor: default;
            }
        }
    }
}

.saveAsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .saveAsInput {
        padding: 1px 5px;
        width: 200px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid $dark-400;
    }
}
