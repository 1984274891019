@import '../../../styles/variables';

.playerCompare {
    //overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 24px;

    h1 {
        font-weight: 600;
        color: $dark-100;
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 40px;
        margin: unset;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
        border: 1px solid $primary-500;
        border-radius: 4px;
        gap: 24px;
        padding: 24px;
        background-color: $light-200;
        position: relative;

        .separator {
            position: absolute;
            width: 0;
            height: calc(100% + 88px);
            border-right: 1px dotted $primary-300;
            top: -65px;
            left: 50%;
        }

        .filters {
            display: flex;
            gap: 48%;

            @media (max-width: $xl) {
                gap: 30%;
            }
        }

        .playerCardContainer {
            display: flex;
            justify-content: space-between;
            position: relative;

            .playerInfoCard {
                display: flex;
                flex-direction: column;
                border: 1px solid $light-400;
                border-radius: 4px;
                padding: 24px;
                background-color: $light-100;
                gap: 17px;
                flex: 0 0 30%;
                max-width: 30%;

                @media (max-width: $xl) {
                    flex: 0 0 35%;
                    max-width: 35%;
                }

                .nameAndIndex {
                    position: relative;
                    gap: 10px;
                    min-width: 50%;

                    .nameAndPicture {
                        display: flex;
                        align-items: center;
                        gap: 18px;
                        cursor: pointer;
                        max-width: calc(100%);

                        @media (max-width: $xl) {
                            gap: 10px;
                            max-width: calc(100% - 81px - 10px);
                        }

                        .pictureContainer {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 101px;
                            min-width: 101px;
                            height: 101px;
                            border: 6px solid $player-A;
                            border-radius: 50%;
                            overflow: hidden;

                            @media (max-width: $xl) {
                                width: 81px;
                                min-width: 81px;
                                height: 81px;
                            }

                            @import 'src/styles/category';
                            @import 'src/styles/playerimage';
                        }

                        .name {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 5px;
                            max-width: 100%;
                            min-width: 50%;

                            .firstName,
                            .surName {
                                color: $primary-200;
                                margin: 0;
                                font-family: 'Open Sans';

                                @media (max-width: $xl) {
                                    font-size: 1rem;
                                }
                            }

                            .surName {
                                max-width: 360px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-transform: uppercase;
                                white-space: nowrap;
                                font-weight: 700;
                            }
                        }
                    }

                    .borderLineY {
                        height: 100px;
                        width: 1px;
                        border: 1px dotted $primary-400;
                    }

                    .scoutingIndex {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;

                        .indexTitle {
                            // flex: 1 1 auto;
                            color: $dark-100;
                            font-family: 'Montserrat';
                            font-size: 14px;
                            text-transform: capitalize;
                            font-weight: 500;
                        }

                        .valueContainer {
                            flex: 1 0 40px;
                            max-width: 40px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px dotted $secondary-200;
                            border-radius: 50%;

                            .solidBorder {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 90%;
                                height: 90%;
                                border: 2px solid $secondary-200;
                                border-radius: 50%;
                                box-shadow: 0 0 18px $secondary-300;
                            }

                            .value {
                                font-family: 'Montserrat';
                                font-weight: 500;
                            }
                        }
                    }
                }

                .borderLineX {
                    height: 1px;
                    width: 100%;
                    border: 1px dotted $primary-400;
                }

                .playerInfo {
                    display: block;

                    .birthDate,
                    .team,
                    .post,
                    .profile,
                    .internationality {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-family: 'Open Sans';

                        p {
                            margin: 10px 0;
                        }

                        .title {
                            flex: 1 0 50%;
                            text-transform: uppercase;
                            color: $dark-100;
                            font-size: 12px;
                            font-weight: 500;
                        }

                        .value {
                            flex: 1 0 50%;
                            font-size: 14px;
                            color: $dark-100;
                            font-weight: 500;
                        }
                    }

                    .team img {
                        width: 16px;
                    }

                    .logoAndName {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        cursor: pointer;

                        .value {
                            margin: 0;
                        }
                    }
                }

            }

            .barChart {
                display: flex;
                flex-direction: column;
                gap: 15px;
                flex: 0 0 40%;
                max-width: 40%;
                padding: 0 15px;

                @media (max-width: $xl) {
                    flex: 0 0 30%;
                    max-width: 30%;
                }

                .barLine {
                    flex-grow: 1;
                    min-height: 35px;
                    max-height: 35px;
                    width: 100%;
                }
            }
        }

        .pieAndBarChartContainer {
            position: relative;
            display: flex;
            justify-content: flex-start;
            border: 1px solid $primary-500;
            border-radius: 4px;
            padding: 24px;
            background-color: $light-200;

            .selectChart {
                position: absolute;
                z-index: 1;
                // flex: 0 0 20%;
            }

            .highlightedChart {
                flex: 0 0 100%;
                height: 400px;

                .title {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                }

                .bigChartContainer {
                    // width: 180px;
                    height: 100%;
                }
            }
        }
    }

    .barLine {
        position: relative;
        display: flex;

        .label {
            background-color: $light-100;
            position: absolute;
            left: 50%;
            top: 0;
            width: 75px;
            height: 20px;
            text-align: center;
            transform: translate(-50%, 0);
            color: $dark-100;
            padding: 2px 0;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 14px;
            font-style: normal;
            font-weight: 500;
            border-radius: 2px;
            z-index: 3;
        }

        .team {
            background-color: $light-100;
            height: 100%;
            width: 0;
            transition: width 250ms;
            box-shadow: 0 0 30px 0 rgba(0, 140, 85, 0.15);
        }

        .player {
            width: 50%;
            height: calc(100% - 14px);
            position: absolute;
            top: 7px;
            z-index: 2;
            background: {
                image: url('../../../assets/images/background_stripes.svg');
                repeat: repeat-x;
            };
        }

        .right,
        .left {
            width: 50%;
            height: 100%;
            position: relative;
        }

        .left {
            display: flex;
            justify-content: flex-end;

            .team {
                border-top: 2px solid #F2CB85;
                border-bottom: 2px solid #F2CB85;
                border-left: 2px solid #F2CB85;
            }
            .player {
                width: 0;
                background-color: #F2CB85;
            }
        }

        .right {
            .team {
                border-top: 2px solid #B8DEC2;
                border-bottom: 2px solid #B8DEC2;
                border-right: 2px solid #B8DEC2;
            }

            .player {
                width: 0;
                background-color: #B8DEC2;
            }
        }

    }


}
