@import 'src/styles/variables';

.toggleList {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary-300;
    border-radius: 4px;
    height: 31px;
    color: $primary-300;
    background-color: $light-100;
    gap: 8px;
    cursor: pointer;
    white-space: nowrap;

    &[disabled] {
        color: $dark-400 !important;
        border-color: $dark-600;
    }

    @media (max-width: $tablet) {
        border: none;
    }

    &:active {
        transform: scale(0.98);
    }

    &.inList {
        background-color: $primary-300;
        color: white;

        img {
            filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(136deg) brightness(103%) contrast(101%);
        }
    }

    &.loading {
        color: $primary-300;
        background-image: linear-gradient(90deg, white 0%, white 25%, $primary-500 50%, white 75%, white 100%);
        background-size: 200% 100%;
        animation: 0.7s linear 0s infinite running loading;

        img {
            filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
        }
    }
    @keyframes loading {
        0% {
            background-position: 200%;
        }
        100% {
            background-position: 0%;
        }
    }

    p {
        font-size: 13px;

        @media (max-width: $tablet) {
            display: none;
        }
    }

    .addIcon {
        filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
    }
}
