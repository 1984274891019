@import '../../../styles/variables';

.shadowTeamBox {
    background-color: $light-100;
    padding: 20px 24px;
    height: calc(100vh - 140px);
    //height: 1000px;

    //@media (max-width: $xl) {
    //  max-height: 70vh;
    //}

    &.fullScreen {
        position: fixed;
        left: 0;
        top: 0;
        max-height: unset;
        width: 100vw;
        height: 100svh;
        z-index: 1;
        padding: 12px 12px;
    }


    .buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        h3 {
            width: 100%;
            margin: 0 0 0 20px;
            text-align: right;
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 20px;
        }

        .filterPageButton {
            &[disabled] {
                color: $dark-400 !important;
            }

            &.enabled {
                background-color: $primary-300;
                color: $light-100;
            }

            &.loading {
                color: $primary-300;
                background-image: linear-gradient(90deg, white 0%, white 25%, $secondary-300 50%, white 75%, white 100%);
                background-size: 200% 100%;
                animation: 0.7s linear 0s infinite running loading;

                img {
                    filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
                }
            }
            @keyframes loading {
                0% {
                    background-position: 200%;
                }
                100% {
                    background-position: 0%;
                }
            }
        }
    }
}

.dropdownToggle {
    position: relative;
    padding-right: 20px !important;

    &::after {
        content: '';
        position: absolute;
        right: 5px;
        top: 15px;
        border-top: 5px solid $secondary-100;
        border-bottom: 0 none transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        width: 0;
        height: 0;
    }

    .dropdownMenu {
        position: absolute;
        left: 0;
        top: 40px;
        z-index: 1;
        border: 1px solid black;
        background-color: #eeeeee;

        .dropdownItem {
            display: block;
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #cccccc;
            }

            &.disabled {
                color: #888888;
                pointer-events: none;
                cursor: default;
            }
        }
    }
}

.saveAsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .saveAsInput {
        padding: 1px 5px;
        width: 200px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid $dark-400;
    }
}
