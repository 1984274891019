@import '../../../styles/variables';

.fileUploader {
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: $light-200;
}

.maxSizeAndFormat {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    margin-bottom: 5px;

    p {
        font-size: 12px;
    }
}

.dropArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 14% 0;
    width: 100%;
    height: 0;
    background-color: $light-100;
    border: 1px solid $primary-200;
    border-radius: 6px;
    color: $primary-100;

    @media (max-width: $lg) {
        padding: 25% 0;
    }
    @media (max-width: $md) {
        padding: 25% 0;
    }

    &.dropInactive {
        background-color: #ffffff;
    }

    &.dropActive {
        background-color: #ffcccc;
    }

    &.dropHighlight {
        background-color: #ff9999;
    }

    &.dropAccepted {
        background-color: #b6ffb6;
    }

    .fileName {
        color: $primary-100;
        font-weight: 600;
    }
}

.uploadButton {
    position: relative;
    width: 150px;
    height: 35px;
    border: 1px solid $primary-100;
    border-radius: 6px;
    padding: 5px;
    background-color: $light-100;
    float: right;
    color: $primary-100;
    cursor: pointer;

    &:hover {
        background-color: $primary-500;
    }

    &:active {
        transform: scale(0.98);
    }

    input[type="file"] {
        opacity: 0;
        position: absolute;
        left: 0;
        width: 150px;
        height: 35px;
        top: 0;
    }
}
