@import 'src/styles/variables';

.TabAccordion {
    width: 100%;
    height: 100%;

    .TabHeader {
        display: flex;
        align-items: center;
        height: 48px;
        width: 100%;
        border-bottom: 1px solid $primary-500;
        background-color: $light-100;
        flex-wrap: nowrap;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .TabItem {
            flex-basis: 100%;
            flex-grow: 1;
            color: $primary-300;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            //cursor: pointer;

            &.active {
                position: relative;
                font-weight: 600;
                color: $primary-200;

                &:after {
                    content: '';
                    position: absolute;
                    height: 3px;
                    background-color: $primary-400;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                }
            }

        }
    }

    .TabContent {
        padding: 10px;
        background-color: $light-200;
        height: calc(100% - 41px);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
