@import 'src/styles/variables';

.mapBox {
    display: flex;
    padding: 24px;
    background-color: $light-100;

    @media (max-width: $xxl) {
        height: 100%;
        max-height: none;
    }

    .continentSelect {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 0;
        flex-basis: 0;
        transition: all 250ms;

        &.countryView {
            flex-basis: 216px;
            border: {
                width: 1px 0 1px 1px;
                color: $primary-500;
                bottom-left-radius: 5px;
                top-left-radius: 5px;
                style: solid;
            }

            & + .customLeafletContainer {
                border-left: unset;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        .continentOption {
            flex-basis: 100%;
            width: 100%;
            background: $light-200;
            border-width: 0 1px 1px 0;
            border-style: solid;
            border-color: $primary-500;
            outline: none;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: left;
            padding-left: 24px;
            color: $dark-300;
            cursor: pointer;

            &:first-child {
                border-top-left-radius: 5px;
            }

            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom: unset;
            }

            &.active {
                background-color: $light-100;
                border-right: unset;
            }
        }
    }

    .customLeafletContainer {
        flex-basis: 100%;
        border: 1px solid $primary-500;
        border-radius: 5px;
    }

    .stretch {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(#47A16D, 0.5);
        border-radius: 15px;

        button {
            cursor: pointer;
        }
    }

    svg {
        path {
            stroke: #47A16D;
            stroke-width: 2;
            fill: #ECF6EF;
            fill-opacity: 1;

            &:hover {
                fill: #47A16D;
                fill-opacity: 1;
                cursor: pointer;
            }
        }
    }
}

.hidden {
    display: none;
}

.customLeafletContainer {
    background: white;
    min-height: 80vh;
    max-height: 80vh;
}
