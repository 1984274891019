@import '../../../styles/variables';

table {
    border-collapse: collapse;
}

.listHeader {
    width: 100%;
    height: 35px;
    color: $dark-100;
    text-transform: uppercase;
    font-family: 'Open Sans';

    th {
        position: sticky;
        top: -1px;
        background-color: $light-200;
        z-index: 100;
    }

    .playerName,
    .classification,
    .birthYear,
    .teamName,
    .expiration,
    .date,
    .user {
        text-align: left!important;
    }

    .playerName,
    .classification,
    .birthYear,
    .teamName,
    .date,
    .expiration,
    .user,
    .post,
    .minutes,
    .fradiIndex,
    .shadowTeam,
    .playerList {
        font-size: 0.8rem;
    }

    .playerPictureCell {
        position: relative;
        z-index: 100;

        .playerPicture {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            overflow: hidden;
        }
    }

    .birthYear {
        white-space: nowrap;
    }

    .date,
    .expiration,
    .post,
    .minutes,
    .preview {
        text-align: center;
    }
}

.downloadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    fieldset {
        position: relative;
        border: 0 none transparent;

        .downloadInput {
            padding: 1px 5px;
            width: 200px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid $dark-400;
        }

        span {
            position: absolute;
            right: 16px;
            top: 12px;
        }
    }
}

.search {
    border: 1px solid $light-400;
    border-radius: 6px;
    background-color: $light-100;
    height: 40px;
    padding: 0 10px;
    color: $dark-200;
    opacity: 1!important;

    @media (max-width: $tablet) {
        font-size: 18px;
    }
}
