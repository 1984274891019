@import 'src/styles/variables';

.mapPopup {
    display: flex;
    flex-direction: column;
    width: 335px;

    &Header {
        height: 30px;
        background: linear-gradient(90deg, $light-300 0%, rgba(255, 255, 255, 0) 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 13px;
        border-left: 3px solid $primary-200;

        .headerTitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
        }

    }

    &Content {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 8px;
        padding: 10px;
        border-bottom: 3px solid $primary-300;

        .category {
            width: 38px;
            height: 38px;
            flex-grow: 0;
            flex-shrink: 0;
            background-color: $light-100;
            cursor: pointer;
            border: 1px solid black;
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            position: relative;
            overflow: hidden;

            &Value {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: $dark-100;
            }

            &::after {
                content: 'A';
                position: absolute;
                background-color: black;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                width: 68px;
                height: 68px;
                border-radius: 50%;
                top: -50px;
                left: 50%;
                transform: translate(-50%, 0);
                color: $light-100;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }

            &.categoryA {
                border-color: $player-A;

                &::after {
                    background-color: $player-A;
                }
            }

            &.categoryBplus {
                border-color: $player-Bplus;

                &::after {
                    content: 'B+';
                    background-color: $player-Bplus;
                }
            }

            &.categoryB {
                border-color: $player-B;

                &::after {
                    content: 'B';
                    background-color: $player-B;
                }
            }

            &.categoryC {
                border-color: $player-C;

                &::after {
                    content: 'C';
                    background-color: $player-C;
                }
            }

            &.categoryD {
                border-color: $player-D;

                &::after {
                    content: 'D';
                    background-color: $player-D;
                }
            }

            &.categoryX {
                border-color: $player-X;

                &::after {
                    content: 'X';
                    background-color: $player-X;
                }

                //&::after {
                    //  content: '';
                    //  background-color: $primary-400;
                    //  background: {
                        //    image: url("../../../assets/images/icon_star_empty_white.svg");
                        //    repeat: no-repeat;
                        //    position: bottom 3px center;
                        //    size: 12px;
                        //  }
                        //}

            }

            &.categoryP {
                border-color: $player-P;

                &::after {
                    content: 'P';
                    background-color: $player-P;
                }
            }
        }
    }
}
