@import 'src/styles/variables';

.footballField {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .footballFieldSelect {
        max-width: 252px;

        @media (max-width: $xl) {
            align-self: flex-start;
        }
    }

    .footballPitch {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 10px;
        width: 100%;
        max-width: 300px;
        aspect-ratio: 0.68;
        background: {
            color: $primary-500;
            image: url("../../../../assets/images/footballPitch.png");
        }

        .pitchLines {
            height: calc(100%);
            width: calc(100%);
        }
    }

    .positionBox {
        position: absolute;
        border: 2px solid $primary-300;
        border-radius: 50%;
        width: 2.3rem;
        height: 2.3rem;
        transform: translate(-50%, -50%);
        background-color: $primary-600;
        color: $primary-300;
        font-weight: 600;

        .position {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            user-select: none;

            .positionButton {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 50%;
                width: 100%;
                height: 100%;
                outline: none;
                background-color: transparent;
                color: $primary-300;
                font-size: 10px;
                cursor: pointer;

                &.disabled {
                    background-color: $dark-500;
                    cursor: default;
                }

                &.selected {
                    &.position-0 {
                        background-color: $position-1 !important;
                        color: $primary-600;
                    }

                    &.position-1 {
                        background-color: $position-2 !important;
                        color: $primary-600;
                    }

                    &.position-2 {
                        background-color: $position-3 !important;
                        color: $primary-600;
                    }

                    &.disabled {
                        background-color: $dark-300;
                        color: $primary-600;
                    }
                }
            }
        }
    }
}
