&.category-a {
    border-color: $player-A;
}
&.category-bplus {
    border-color: $player-Bplus;
}
&.category-b {
    border-color: $player-B;
}
&.category-c {
    border-color: $player-C;
}
&.category-d {
    border-color: $player-D;
}
&.category-x {
    border-color: $player-X;
}
&.category-p {
    border-color: $player-P;
}
&.category-none {
    border-color: $player-none;
}
