@import '../../../styles/variables';

.headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 40px;
  border-bottom: 1px solid $primary-400;
  position: relative;

  @media (max-width: $xl) {
    padding: 0 20px;
  }

  .logoAndName {
    position: relative;
    display: flex;
    align-items: center;
    gap: 25px;
    color: $dark-100;
    text-decoration: none;

    .fradiLogo {
      position: relative;
      top: 12px;
      height: 70px;
    }

    .appName {
      display: flex;
      gap: 10px;
      text-transform: uppercase;

      .scouting {
        font-weight: 500;
      }
    }

    @media (max-width: $xl) {
      gap: 8px;

      h1 {
        font-size: 24px;
      }
    }
  }

  .menu {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .menuItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 80px;
      color: $primary-200;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      h2 {
        font-size: 20px;
      }

      &.activeMenu,
      &:focus,
      &:active {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0%;
          bottom: 10px;
          background: $primary-200;
          height: 5px;
          display: block;
        }

        h2 {
          font-weight: 600;
        }
      }

      &.disabled {
        color: $dark-300;
        cursor: default;
        pointer-events: none;
      }

      @media (max-width: $xl) {
        width: 128px;

        h2 {
          font-size: 20px;
          line-height: 24px;
          margin: unset;
        }
      }
    }
  }

  .headerActions {
    display: flex;
    gap: 12px;
    align-items: center;

    .languageChooser {
      display: flex;
      margin-right: 64px;
      gap: 10px;

      .lang {
        color: $primary-300;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active {
          font-weight: 700;
        }
      }
    }

    .welcomeText {
      margin-right: 24px;
      font-size: 1rem;

      @media (max-width: $xl) {
        display: none;
      }

      span {
        font-weight: 600;
      }
    }

    a {
      display: flex;
      align-items: center;
    }
    a:active {
      transform: scale(0.9);
    }

    .actionIcon {
      height: 24px;
      width: 24px;

      &.hidden {
        display: none;
      }
    }
  }
}

.search {
    display: flex;
    justify-content: space-between;
    height: 48px;
    border: 1px solid $light-400;
    border-radius: 4px;
    padding: 5px 15px;
    background-color: $light-100;

    input {
        border: none;
        width: calc(100% - 16px);
        outline: none;
        background-color: $light-100;
    }

    .searchIcon {
        width: 16px;
        height: auto;
        background: {
            image: url('../../../assets/images/icons/icon_search.svg');
            repeat: no-repeat;
            position: center center;
            size: contain;
        }
        content: '';
    }
}

.searchList {
    margin-top: 15px;
    height: 50vh;
    overflow: auto;
    position: relative;

    .playerItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 12px;
        padding-bottom: 6px;
        padding-top: 6px;
        padding-right: 20px;
        border-bottom: 1px dotted $primary-400;

        &:last-child {
            border-bottom: none;
        }

        .playerPicture {
            img {
                width: 58px;
                height: 58px;
                object-fit: cover;
                border-radius: 50%;
                border: 5px solid $primary-300;
            }
        }

        .playerName {
            font-size: 14px;
            cursor: pointer;
            width: 50%;
            color: $primary-300;
            font-weight: 600;
        }

        .teamName {
            font-size: 14px;
            cursor: pointer;
            width: 50%;
            color: $dark-400;
            font-weight: 600;
        }

        .teamPicture {
            img {
                object-fit: cover;
                width: 32px;
                height: 32px;
            }
        }
    }
}
