@import 'src/styles/variables';

.backDrop {
    // a térkép területek 500-környékén eltűnnek, de a jobb alsó és bal felső sarokban a layerek csak 1000-nél
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.40);
    display: flex;
    align-items: center;
    justify-content: center;

    .modalBox {
        width: 90%;
        min-width: 60%;
        max-width: 1900px;
        height: 75%;
        background-color: $light-100;
        display: flex;
        flex-direction: column;
        padding: 16px 32px 32px;

        @media (max-width: $xl) {
            padding: 16px 12px 12px 12px;
        }

        &Header {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: relative;
            width: 100%;

            .modalTitle {
                width: 100%;
                height: 40px;
                text-align: center;
                margin: unset;
                padding: unset;font-size: 22px;
                font-family: Montserrat, sans-serif;
                font-weight: 600;
            }

            .close {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(50%);

                @media (max-width: $xl) {
                    right: 20px;
                }

                img {
                    width: 32px;
                    height: 32px;
                }
            }

        }

        &Content {
            height: 100%;
            overflow: hidden;
        }
    }

    .saveAsBox {
        min-width: 400px;
        max-width: 400px;
        height: 180px;
    }
}

.fileUploaderModal {
    min-width: 800px !important;
    width: 800px !important;
    height: 420px !important;

    @media (max-width: $lg) {
        min-width: 500px !important;
        width: 500px !important;
    }
    @media (max-width: $sm) {
        min-width: 350px !important;
        width: 350px !important;
    }
}

.downloadBox {
    min-width: 400px !important;
    max-width: 400px !important;
    min-height: 300px !important;
    max-height: 300px !important;
}

.positionModifierBox {
    height: 87vh !important;
}
