@import 'src/styles/variables';

.tooltip {
    position: absolute;
    z-index: 3000;
    border: 1px solid $primary-300;
    border-radius: 4px;
    background-color: $secondary-400;
    padding: 5px;
    width: auto;
    font-size: 13px;
    text-align: center;
}

.inactive {
    border-color: $dark-300;
    background-color: $dark-600;
    font-style: italic;
}
