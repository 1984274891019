@import 'src/styles/variables';

.Category {
    &Box {
        height: 24px;
        width: 194px;
        padding: 4px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light-300;
        flex-shrink: 0;
        flex-grow: 0;

        .categoryButton {
            width: 26px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $dark-400;
            color: $light-100;
            border-radius: 12px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            // &[disabled],
            // &.disabled {
            //     background-color: $dark-400 !important;
            // }

            &.selected {
                outline: 4px solid $dark-300;
            }

            &.category-a {
                background-color: $player-A;
                outline-color: $player-A;

                &:focus-visible {
                    outline: 3px solid $player-A;
                    background-color: $player-A;
                }
            }
            &.category-bplus {
                background-color: $player-Bplus;
                outline-color: $player-Bplus;

                &:focus-visible {
                    outline: 4px solid $player-Bplus;
                    background-color: $player-Bplus;
                }
            }
            &.category-b {
                background-color: $player-B;
                outline-color: $player-B;

                &:focus-visible {
                    outline: 4px solid $player-B;
                    background-color: $player-B;
                }
            }
            &.category-c {
                background-color: $player-C;
                outline-color: $player-C;

                &:focus-visible {
                    outline: 3px solid $player-C;
                    background-color: $player-C;
                }
            }
            &.category-d {
                background-color: $player-D;
                outline-color: $player-D;

                &:focus-visible {
                    outline: 3px solid $player-D;
                    background-color: $player-D;
                }
            }
            &.category-x {
                background-color: $player-X;
                outline-color: $player-X;

                &:focus-visible {
                    outline: 3px solid $player-X;
                    background-color: $player-X;
                }
            }
            &.category-p {
                background-color: $player-P;
                outline-color: $player-P;

                &:focus-visible {
                    outline: 3px solid $player-P;
                    background-color: $player-P;
                }
            }
        }
    }
}
