@import 'src/styles/variables';

.categoryCollection {
    height: 100%;

    .categorySelect {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .category {
            flex-basis: 14.2857%;
            height: 46px;
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            position: relative;
            overflow: hidden;
            border: 1px solid $dark-600;
            outline: unset;
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            text-transform: uppercase;
            cursor: pointer;

            &Value {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 14px;
                line-height: 27px;
                color: $dark-100;
            }

            &:after {
                content: '';
                position: absolute;
                background-color: transparent;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $light-100;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.category-a {
                background-color: $light-100;

                &:after {
                    content: 'A';
                    color: $light-100;
                    background-color: $player-A;
                }
            }

            &.category-bplus {
                border-left: none;
                background-color: $light-100;

                &:after {
                    content: 'B+';
                    color: $light-100;
                    background-color: $player-Bplus;
                }
            }

            &.category-b {
                border-left: none;
                background-color: $light-100;

                &:after {
                    content: 'B';
                    color: $light-100;
                    background-color: $player-B;
                }
            }

            &.category-c {
                border-left: none;
                background-color: $light-100;

                &:after {
                    content: 'C';
                    color: $light-100;
                    background-color: $player-C;
                }
            }

            &.category-d {
                border-left: none;
                background-color: $light-100;

                &:after {
                    content: 'D';
                    color: $light-100;
                    background-color: $player-D;
                }
            }

            &.category-x {
                border-left: none;
                background-color: $light-100;

                &:after {
                    content: 'X';
                    color: $light-100;
                    background-color: $player-X;
                }
            }

            &.category-p {
                border-left: none;
                background-color: $light-100;

                &:after {
                    content: 'P';
                    color: $light-100;
                    background-color: $player-P;
                }
            }

            &.active {
                &.category-a {
                    border: none;
                    background-color: $player-A;

                    &::after {
                        color: $player-A;
                        background-color: $light-100;
                    }
                }

                &.category-bplus {
                    border: none;
                    background-color: $player-Bplus;

                    &::after {
                        color: $player-Bplus;
                        background-color: $light-100;
                    }
                }

                &.category-b {
                    border: none;
                    background-color: $player-B;

                    &::after {
                        color: $player-B;
                        background-color: $light-100;
                    }
                }

                &.category-c {
                    border: none;
                    background-color: $player-C;

                    &::after {
                        color: $player-C;
                        background-color: $light-100;
                    }
                }

                &.category-d {
                    border: none;
                    background-color: $player-D;

                    &::after {
                        color: $player-D;
                        background-color: $light-100;
                    }
                }

                &.category-x {
                    border: none;
                    background-color: $player-X;

                    &::after {
                        color: $player-X;
                        background-color: $light-100;
                    }
                }

                &.category-p {
                    border: none;
                    background-color: $player-P;

                    &::after {
                        color: $player-P;
                        background-color: $light-100;
                    }
                }
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: calc(100% - 50px);
        background-color: $light-200;
        padding: 23px 24px;

        .titleBox {
            display: flex;
            padding: 8px 24px;
            gap: 15px;

            .imageBox,
            .nameBox,
            .yearBox,
            .teamBox,
            .postBox,
            .minutesBox,
            .chartBox,
            .actionBox {
                flex-basis: 10%;
                flex-shrink: 0;
                flex-grow: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-transform: uppercase;
                color: $dark-100;
                white-space: nowrap;
            }

            .imageBox {
                flex-basis: 64px;
            }

            .nameBox {
                flex-basis: 120px;
            }

            .yearBox {
                flex-basis: 100px;
            }

            .teamBox {
                flex-basis: 150px;
                flex-grow: 1;
            }

            .postBox {
                flex-basis: 64px;
            }

            .minutesBox {
                flex-basis: 10%;
            }

            .chartBox {
                flex-basis: 64px;
            }

            .actionBox {
                flex-basis: 91px;

                @media (max-width: $tablet) {
                    flex-basis: 40px;
                }
            }
        }

        .playerListContainer {
            display: flex;
            flex-direction: column;
            gap: 12px;
            position: relative;

            .emptyListContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                height: 50%;

                img {
                    margin-top: 50px;
                    height: 150px;
                    width: auto;
                }

                span {
                    color: $dark-300;
                }
            }
        }
    }
}
