@import 'src/styles/variables';

.multiThumbWrapper {
    border: 1px solid $light-400;
    border-radius: 5px;
    padding: 6px 16px;
    background: $light-100;
    width: 100%;

    .sliderHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .headerContent {
            display: flex;
            gap: 5px;
            max-width: calc(100% - 55px);

            .label,
            .labelValue {
                font-family: 'Open Sans';
            }


            .label {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
                color: $dark-100;
            }

            .labelValue {
                font-weight: 600;
                color: $dark-100;
            }
        }

        .headerActions {
            display: flex;
            gap: 5px;

            & > * {
                width: 20px;
                height: 20px;
                padding: 0;
                line-height: 17px;
                border-radius: 50%;
                color: $primary-300;
                background-color: $light-100;

                &:disabled {
                    background: $light-300;
                    color: $dark-300;
                }
            }

            button {
                font-size: 22px;
            }
        }
    }

    .sliderContainer {
        display: flex;
        align-items: center;
        gap: 16px;

        span {
            font-family: 'Open Sans';
            color: $dark-100;
            font-weight: 500;
        }
    }

    .sliderBox {
        position: relative;
        height: 1px;
        flex-basis: 100%;
        border: 1px solid $primary-400;
        background: none;
        border-radius: 5px;

        .svgBackground {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% - 16px);
            left: 8px;
            height: 1px;

            &.solo {
                left: -1px;
                border-radius: 5px;
                width: 100%;
            }
        }

        .slider,
        .slider::-webkit-slider-thumb,
        .slider::-webkit-slider-runnable-track {
            -webkit-appearance: none;
        }

        .slider {
            position: absolute;
            -webkit-appearance: none;
            width: 100%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            margin: 0;
            background: none;
            pointer-events: none;
            opacity: 1;
        }

        @mixin track {
            background: none;
            pointer-events: none;
            height: 100%;
            width: 100%;
        }

        @mixin thumb {
            width: 12px;
            height: 12px;
            border: 2px solid $primary-100;
            background: #FFF;
            border-radius: 50%;
            pointer-events: auto;
            filter: drop-shadow(0px 0px 4px $primary-400);

            @media (max-width: $xl) {
                width: 20px;
                height: 20px;
            }

            &:hover {
                cursor: grab;
            }

            &:active {
                cursor: grabbing;
            }
        }

        @mixin selectedThumb {
            background: $primary-300;
        }

        .slider::-moz-range-track {
            @include track;
        }

        .slider::-webkit-slider-runnable-track {
            @include track;
        }

        .slider::-moz-range-thumb {
            @include thumb;

        }

        .slider::-webkit-slider-thumb {
            @include thumb;
        }

        .slider.selectedThumb::-moz-range-thumb {
            @include selectedThumb;
        }

        .slider.selectedThumb::-webkit-slider-thumb {
            @include selectedThumb;
        }
    }

    &.disabled {
        .sliderHeader .headerContent .label,
        .sliderHeader .headerContent .labelValue {
            color: $primary-500;
        }

        .sliderContainer {
            span {
                color: $primary-500;
            }

            input.slider::-moz-range-thumb {
                background: #C8D9DC;
                border: 2px solid $dark-200;
                opacity: 1 !important;

                &:hover {
                    cursor: default;
                }
            }

            input.slider::-webkit-slider-thumb {
                background: #C8D9DC;
                border: 2px solid $dark-200;
                opacity: 1 !important;

                &:hover {
                    cursor: default;
                }
            }
        }
    }
}
