@import 'src/styles/variables';

.lineContainer {
    position: relative;
    width: 300px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid $light-400;
    background: $light-200;
    overflow: hidden;
}

.progress {
    background-color: $info-100;
    height: 24px;
    // border-radius: 12px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-100;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: width 250ms;

    span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #CC8C58;
        mix-blend-mode: difference;
    }
}
