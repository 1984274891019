@import '../../../styles/variables';

.shortList {

    .contentHeader {
        h1 span {
            display: inline-block;
            margin-right: 10px;
        }
    }

    .filterPageButton {
        &[disabled] {
            color: $dark-400 !important;
        }

        &.loading {
            color: $primary-300;
            background-image: linear-gradient(90deg, white 0%, white 25%, $secondary-300 50%, white 75%, white 100%);
            background-size: 200% 100%;
            animation: 0.7s linear 0s infinite running loading;

            img {
                filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
            }
        }
        @keyframes loading {
            0% {
                background-position: 200%;
            }
            100% {
                background-position: 0%;
            }
        }
    }

    .shortListContent {
        position: relative;
        width: 100%;
        height: calc(100vh - 245px);
        border: 1px solid $primary-500;
        border-radius: 4px;
        background-color: $light-200;
        overflow: hidden;

        .header {
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            border-bottom: 1px solid $primary-500;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: $light-100;

            .filterTab {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 50%;
                height: 100%;
                text-align: center;
                font-family: 'Montserrat';
                color: $primary-300;
                cursor: pointer;

                &:hover {
                    color: $primary-200;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $primary-400;
                        height: 3px;
                        display: block;
                    }
                }
            }
        }

        .shortListContainer {
            position: relative;
            height: 100%;

            &.empty {
                background: {
                    image: url('../../../assets/images/football_field.svg');
                    repeat: no-repeat;
                    position: center;
                    size: 20%;
                };
                .emptyStateText {
                    position: relative;
                    top: 65%;
                    display: block;
                    color: $dark-600;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 13px;
                    text-align: center;
                }
            }

            &.listed {
                background-image: none;
                padding: 5px 24px 32px 24px;

                @media (max-width: $tablet) {
                    padding: 5px 5px 32px 5px;
                }

                .emptyStateText {
                    display: none;
                }

                table {
                    width: 100%;

                    tbody {
                        width: calc(100% - 200px);
                    }
                }

                .listHeader {
                    width: 100%;
                    height: 35px;
                    color: $dark-100;
                    text-transform: uppercase;
                    font-family: 'Open Sans';
                    position: relative;

                    th {
                        position: sticky;
                        top: -1px;
                        background-color: $light-200;
                        z-index: 100;

                        &:first-of-type {
                            border-left: 1px solid $light-200;
                        }
                    }

                    .playerName,
                    .classification,
                    .birthYear,
                    .teamName,
                    .post,
                    .minutes,
                    .date,
                    .expiration,
                    .shadowTeam,
                    .playerList,
                    .fradiIndex,
                    .shortList {
                        font-size: 0.8rem;
                        text-align: left;
                    }

                    .playerPicture {
                        width: 64px;
                        box-sizing: border-box;
                        overflow: hidden;
                    }

                    .birthYear {
                        white-space: nowrap;
                    }

                    .post {
                        text-align: center;
                    }

                    .date {
                        text-align: center;
                    }

                    .minutes {
                        text-align: center;
                    }

                    .fradiIndex {
                        text-align: center;
                    }

                    .shadowTeam {
                        text-align: center;
                    }

                    .playerList {
                        text-align: center;
                    }

                    .shortList {
                        text-align: center;
                    }
                }
            }
        }
    }

    .shortListContentModal {
        @extend .shortListContent;
        height: calc(100% - 90px);
    }
}

.dropdownToggle {
    position: relative;
    padding-right: 20px !important;

    &::after {
        content: '';
        position: absolute;
        right: 5px;
        top: 15px;
        border-top: 5px solid $secondary-100;
        border-bottom: 0 none transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        width: 0;
        height: 0;
    }

    .dropdownMenu {
        display: none;
        position: absolute;
        right: 0;
        top: 40px;
        z-index: 1;
        border: 1px solid black;
        background-color: #eeeeee;

        .dropdownItem {
            display: block;
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #cccccc;
            }

            &.disabled {
                color: #888888;
                pointer-events: none;
                cursor: default;
            }
        }
    }

    &:focus {
        .dropdownMenu {
            display: block;
        }
    }
}

.search {
    border: 1px solid $light-400;
    border-radius: 6px;
    background-color: $light-100;
    height: 40px;
    padding: 0 10px;
}
