@import '../../../styles/variables';

.listElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 0;
    // position: relative;
    border: 1px solid $primary-400;
    border-radius: 4px;
    padding: 0 10px 0 25px;
    width: 100%;
    height: 138px;
    color: $primary-300;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    .delete {
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;

        &:hover {
            color: $info-100;
        }
    }

    .topSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
        width: 100%;
        flex: 0 0 60%;
        gap: 5px;

        .playerPicture {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            border: 5px solid $player-A;
            border-radius: 50%;
            width: 64px;
            height: 64px;
            overflow: hidden;

            @import 'src/styles/category';
            @import 'src/styles/playerimage';
        }

        .playerName {
            flex: 3 0 auto;
            cursor: pointer;
            text-align: left;

            .firstName,
            .surName,
            .birthYear {
                margin: 0;
                color: $primary-200;
                line-height: 1.6em;
                font-size: 14px;
            }

            .surName {
                text-transform: uppercase;
                font-weight: 700;
            }

            .birthYear {
                font-weight: 100;
            }
        }

        .polarChart {
            width: 100px;
            height: 80px;
        }
    }

    .bottomSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex: 0 0 40%;

        .label {
            text-align: left;
            color: $dark-300;
            text-transform: uppercase;
        }

        .value {
            text-align: left;
            color: $dark-100;
            font-weight: bold;
        }

        .teamName {
            width: 50%;

            .value {
                display: flex;
                align-items: center;
                white-space: nowrap;
                height: 20px;
            }

            img {
                padding-right: 5px;
                width: 19px;
                height: 19px;
            }

            .nameText {
                width: calc(100% - 19px);
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                font-size: 13px;
            }
        }

        .post {
            width: 20%;
        }

        .minutes {
            width: 30%;
        }
    }

}
