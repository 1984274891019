@import 'src/styles/variables';

.listElement {
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid $light-400;
    border-radius: 4px;
    padding-left: 24px;
    width: 100%;
    height: 80px;
    background-color: $light-100;
    font-family: 'Open Sans';

    &.hasButton {
        padding-right: 24px;
    }

    .playerPicture {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border: 5px solid transparent;
        border-radius: 50%;
        width: 64px;
        min-width: 64px;
        height: 64px;
        box-sizing: border-box;
        overflow: hidden;

        @import 'src/styles/category';
        @import 'src/styles/playerimage';
    }

    .playerName {
        width: 25%;
        cursor: pointer;
        overflow: hidden;

        .firstName,
        .surName {
            color: $primary-200;
            margin: 0;
            line-height: 1.6em;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .surName {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .birthYear {
        width: 10%;
        font-size: 14px;
    }

    .teamName {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 20%;

        img {
            width: 19px;
            height: 19px;
        }

        .nameText {
            font-size: 14px;
            cursor: pointer;
        }
    }

    .expiration {
        width: 15%;
        font-size: 14px;
    }

    .post {
        width: 5%;
        text-align: center;
        font-size: 14px;
    }

    .minutes {
        width: 10%;
        text-align: center;
        font-size: 14px;
    }

    .date {
        width: 10%;
        text-align: center;
        font-size: 14px;
    }

    .fradiIndex {

        .dottedCircle {
            width: 45px;
            height: 45px;
            border: 1px dotted $secondary-200;
            border-radius: 50%;
            padding: 3px;

            .valueCircle {
                width: 100%;
                height: 100%;
                border: 2px solid $secondary-200;
                border-radius: 50%;
                box-shadow: 0 0 20px 0 rgba($secondary-200, 0.50);
                background-color: $light-100;
                display: flex;
                justify-content: center;
                align-items: center;

                .indexValue {
                    color: $secondary-200;
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: uppercase;
                }
            }
        }
    }

    .polarChart {
        width: 10%;
        height: 80px;
    }

    .shadowTeam,
    .playerList,
    .shortList {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        width: 10%;

        .dataContainer {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
                font-size: 14px;
            }

            img {
                filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
            }
        }
    }
}
