@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
    overscroll-behavior: none;
}

body {
    margin: 0;
    background-color: $light-100;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;

    @media (max-width: $xxl) {
        font-size: 16px;
    }
}


img,
div {
    box-sizing: border-box;
}

p {
    font-size: 1.14rem;

    @media (max-width: $xxl) {
        font-size: 1.28rem;
    }
}

h1,
h2,
h3,
.menu-bold,
.menu-medium {
    font-family: 'Montserrat', sans-serif;
}

// Heading bold
h1 {
    font-size: 1.71rem;
    font-weight: 800;
}

// Heading 1
h2 {
    font-size: 1.71rem;
    font-weight: 400;
}

// Heading 2
h3 {
    font-size: 1.57rem;
    font-weight: 400;
}

.menu-bold {
    font-size: 1.42rem;
    font-weight: 700;
}

.menu-medium {
    font-size: 1.42rem;
    font-weight: 600;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 75px;
    height: 40px;
    color: $light-100;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

// Button with full background
.primary {
    background-color: $primary-300;

    &:hover {
        background-color: $primary-400;
    }

    &:active {
        background-color: $primary-200;
    }
}

.primary-outline {
    border: 1px solid $primary-300;

    &:hover {
        border: 1px solid $primary-400;
    }

    &:active {
        border: 1px solid $primary-200;
    }
}

// Button with full background
.secondary {
    background-color: $secondary-200;

    &:hover {
        background-color: $secondary-300;
    }

    &:active {
        background-color: $primary-100;
    }
}

.secondary-outline {
    border: 1px solid $secondary-200;

    &:hover {
        border: 1px solid $secondary-300;
    }

    &:active {
        border: 1px solid $primary-100;
    }
}

//.tabBoxButton.active {
//    background-color: $light-100!important;
//    font-weight: 600;
//}

.leaflet-popup {
    .leaflet-popup-content-wrapper {
        border-radius: unset;
        padding: 0;
        box-shadow: 0 0 20px rgba(21, 75, 43, 0.2);
    }

    .leaflet-popup-content {
        margin: 0 -1px 0 0;
        width: 336px !important;
    }

    .leaflet-popup-tip {
        background: $primary-300;
    }
}

.btn {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &.wrap {
        white-space: normal;
    }

    cursor: pointer;
    --bg-color: #{$primary-300};
    --hov-bg-color: #{$primary-400};
    --txt-color: #{$light-100};
    --hov-txt-color: #{$light-100};
    --bord-color: #{$primary-300};
    --hov-bord-color: #{$primary-400};

    &-div,
    &.icon {
        padding: 0;
        border: unset;
        background: transparent;
        box-shadow: unset;
        cursor: pointer;
    }


    &.btn-subtle,
    &.btn-danger,
    &.btn-secondary,
    &.btn-primary {
        padding: 10px 16px;
        display: flex;
        gap: 8px;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        transition: all 250ms;
        border-radius: 4px;
        border: 1px solid var(--bord-color);
        background-color: var(--bg-color);
        color: var(--txt-color);

        img {
            transition: all 250ms;
        }

        &:hover:not(:disabled) {
            border: 1px solid var(--hov-bord-color);
            background-color: var(--hov-bg-color);
            color: var(--hov-txt-color);
        }

        &:disabled {
            cursor: default;
        }
    }

    &.btn-primary {

        img {
            filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(136deg) brightness(103%) contrast(101%);
        }

        &.btn-outline {
            --hov-bord-color: #{$primary-300};
            --bord-color: #{$primary-300};
            --bg-color: #{$light-100};
            --txt-color: #{$primary-300};
            img {
                filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
            }

            &:hover {
                img {
                    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(136deg) brightness(103%) contrast(101%);
                }
            }
        }

        &.btn-ghost {
            --bord-color: transparent;
            --bg-color: transparent;
            --hov-bg-color: transparent;
            --hov-bord-color: transparent;
            --txt-color: #{$primary-300};
            --hov-txt-color: #{$primary-400};
        }
    }

    &.btn-secondary {
        --bord-color: #{$secondary-100};
        --hov-bord-color: #{$secondary-400};
        --bg-color: #{$secondary-100};
        --hov-bg-color: #{$secondary-400};
        --hov-txt-color: #{$secondary-100};

        img {
            filter: invert(60%) sepia(86%) saturate(446%) hue-rotate(6deg) brightness(85%) contrast(85%);
        }

        &.btn-outline {
            --bg-color: #{$light-100};
            --txt-color: #{$secondary-100};
            --hov-bord-color: #{$secondary-100};
        }

        &.btn-ghost {
            --bord-color: transparent;
            --bg-color: transparent;
            --hov-bg-color: transparent;
            --hov-bord-color: transparent;
            --txt-color: #{$secondary-100};
            --hov-txt-color: #{$secondary-300};
        }
    }

    &.btn-primary:disabled,
    &.btn-danger:disabled,
    &.btn-secondary:disabled,
    &.btn-subtle {
        --bord-color: #{$dark-600};
        --hov-bord-color: #{$secondary-400};
        --txt-color: #{$dark-400};
        --bg-color: #{$dark-600};
        --hov-bg-color: #{$light-400};
        --hov-txt-color: #{$dark-300};

        img {
            filter: invert(70%) sepia(0%) saturate(0%) hue-rotate(219deg) brightness(95%) contrast(90%);
        }

        &:hover {
            img {
                filter: invert(56%) sepia(12%) saturate(8%) hue-rotate(315deg) brightness(88%) contrast(85%);
            }
        }

        &.btn-outline {
            --bg-color: #{$light-100};
            --txt-color: #{$dark-400};
            --hov-txt-color: #{$dark-300};
            --hov-bord-color: #{$dark-300};

            &:hover {
                img {
                    filter: invert(56%) sepia(12%) saturate(8%) hue-rotate(315deg) brightness(88%) contrast(85%);
                }
            }
        }

        &.btn-ghost {
            --bord-color: transparent;
            --bg-color: transparent;
            --hov-bg-color: transparent;
            --hov-bord-color: transparent;
            --txt-color: #{$dark-400};
            --hov-txt-color: #{$dark-300};
        }
    }

    &.btn-danger {
        --bord-color: #{$state-100};
        --hov-bord-color: #{$state-100};
        --bg-color: #{$light-100};
        --hov-bg-color: #{$state-100};
        --hov-txt-color: #{$light-100};

        img {
            filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(136deg) brightness(103%) contrast(101%);
        }

        &.btn-outline {
            --bg-color: #{$light-100};
            --txt-color: #{$state-100};
            --hov-bord-color: #{$state-100};

            img {
                filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
            }

            &:hover {
                img {
                    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(136deg) brightness(103%) contrast(101%);
                }
            }
        }
    }

    img {
        height: 16px;
        width: 16px;
    }

    &.disabled {
        cursor: default;
        color: #444444;

        img {
            filter: invert(70%) sepia(0%) saturate(0%) hue-rotate(219deg) brightness(95%) contrast(90%);
        }
    }
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    padding: 10px 16px;
    gap: 8px;
    border: none;
    background-color: $light-100;
    cursor: pointer;

    &:active {
        transform: scale(0.97);
    }

    img {
        position: relative;
        height: 100%;
    }

    &.grey {
        border: 1px solid $dark-400;
        color: $dark-400;

        img {
            filter: invert(84%) sepia(0%) saturate(1718%) hue-rotate(206deg) brightness(80%) contrast(88%);
        }

        &:hover {
            border: 1px solid $light-400;
            color: $light-400;

            img {
                filter: invert(98%) sepia(1%) saturate(83%) hue-rotate(354deg) brightness(82%) contrast(79%);
            }
        }

        &:active {
            border: 1px solid $dark-300;
            color: $dark-300;
        }
    }
}

.contentContainer {
    height: 100%;
    padding: 20px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $light-100;
    box-shadow: 0px 0px 40px rgba(21, 75, 43, 0.1);

    @media (max-width: $xxl) {
        padding: 20px 10px;
    }

    .contentHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-weight: 600;
        }
    }

    .buttonsAndFilters {
        display: flex;
        gap: 12px;
    }
}

.separatorLine {
    border: {
        style: dotted;
        width: 1px;
        color: transparent;
    };

    &.horizontal {
        width: 100%;
        border-bottom: {
        color: $primary-400;
        };
    }
    &.vertical {
        height: 100%;
        border-left: {
            color: $primary-400;
        };
    }
}

textarea {
    resize: vertical;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    .Toastify__toast-body,
    .Toastify__close-button {
        color: black;
    }
}

.sortableColumn {
    cursor: pointer;

    &::after {
        display: inline-block;
        content: '';
        position: relative;
        left: 5px;
        top: -2px;
        width: 0;
        height: 0;
    }

    &.sort-asc::after {
        border-top: 5px solid $secondary-100;
        border-bottom: 0 none transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }

    &.sort-desc::after {
        border-top: 0 none transparent;
        border-bottom: 5px solid $secondary-100;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }
}
