@import 'src/styles/variables';

.listElement {
    display: flex;
    flex-direction: column;
    background-color: $light-100;
    border: 1px solid $light-400;
    border-radius: 4px;
    padding: 12px;
    row-gap: 16px;

    .basicInfo {
        display: flex;
        justify-content: space-between;

        .pictureAndName {
            display: flex;
            align-items: center;
            gap: 16px;

            .playerPicture {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
                width: 64px;
                border: 5px solid $dark-300;
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;

                @import 'src/styles/category';
                @import 'src/styles/playerimage';
            }

            .personalData {
                background-color: unset;
                border-color: unset;
                outline: 0;
                display: flex;
                flex-direction: column;

                p {
                    line-height: 20px;
                }

                .firstName,
                .surName {
                    color: $primary-200;
                    font-size: 13px;
                    line-height: 20px;
                }

                .surName {
                    font-weight: 600;
                    text-transform: uppercase;

                    .playerName {
                        display: inline-block;
                        max-width: 120px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .birthDate {
                    color: $dark-400;
                    font-size: 13px;
                }
            }
        }

        .chartContainer {
            height: 64px;
            width: 64px;
            flex-shrink: 0;

            svg {
                margin-left: -7.5px;
                margin-top: -7.5px;
            }
        }
    }

    .clubInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 10px;
            color: $dark-300;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        .nameAndLogo {
            display: flex;
            align-items: center;
            gap: 4px;

            .teamLogo {
                height: 16px;
            }
        }

        .teamName,
        .postNumber,
        .value {
            font-size: 12px;
            color: $dark-100;
            user-select: none;
        }

        .teamName {
            cursor: pointer;
        }
    }

    .addToShortlist {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $primary-300;
        border-radius: 4px;
        height: 31px;
        color: $primary-300;
        background-color: $light-100;
        gap: 8px;
        cursor: pointer;

        &:active {
            transform: scale(0.98);
        }

        .addIcon {
            filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
        }
    }
}
