@import '../../../styles/variables';

.listElement {
    border-radius: 4px;
    width: 100%;
    height: 80px;
    background-color: $light-100;
    font-family: 'Open Sans';
    margin-bottom: 10px;

    &.hasButton {
        padding-right: 24px;
    }

    td {
        border-top: 1px solid $light-400;
        border-bottom: 1px solid $light-400;

        &:first-of-type {
            border-left: 1px solid $light-400;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        &:last-of-type {
            border-right: 1px solid $light-400;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 10px;
        }
    }

    .playerPictureCell {
        width: 8%;
        padding: 0 15px;

        .playerPicture {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border: 5px solid transparent;
            border-radius: 50%;
            width: 64px;
            min-width: 64px;
            height: 64px;
            box-sizing: border-box;
            overflow: hidden;

            @import 'src/styles/category';
            @import 'src/styles/playerimage';
        }
    }


    .playerName {
        width: 16%;
        cursor: pointer;
        overflow: hidden;

        @media (max-width: $xxl) {
            width: 14%;
        }

        .firstName,
        .surName {
            color: $primary-200;
            margin: 0;
            line-height: 1.6em;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .surName {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .classification {
        width: 7%;

        @media (max-width: $tablet) {
            width: 8%;
        }
    }

    .birthYear {
        width: 9%;
        font-size: 14px;
    }

    .teamName {
        width: 15%;

        .flexContainer {
            display: flex;
            align-items: center;
            gap: 4px;

            img {
                width: 19px;
                height: 19px;
            }

            .nameText {
                font-size: 14px;
                cursor: pointer;
            }
        }

    }

    .expiration {
        width: 15%;
        font-size: 14px;

        @media (max-width: $tablet) {
            text-align: center;
        }
    }

    .post {
        width: 4%;
        text-align: center;
        font-size: 14px;
    }

    .minutes {
        width: 6%;
        text-align: center;
        font-size: 14px;
    }

    .date {
        width: 10%;
        text-align: center;
        font-size: 14px;
    }

    .fradiIndex {

        @media (max-width: $tablet) {
            width: 8%;
        }

        .dottedCircle {
            width: 45px;
            height: 45px;
            border: 1px dotted $secondary-200;
            border-radius: 50%;
            padding: 3px;
            right: 0px;
            left: 0;
            margin: auto;

            .valueCircle {
                width: 100%;
                height: 100%;
                border: 2px solid $secondary-200;
                border-radius: 50%;
                box-shadow: 0 0 20px 0 rgba($secondary-200, 0.50);
                background-color: $light-100;
                display: flex;
                justify-content: center;
                align-items: center;

                .indexValue {
                    color: $secondary-200;
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: uppercase;
                }
            }
        }
    }

    .polarChart {
        width: 6%;
        height: 80px;
    }

    .shadowTeam,
    .playerList,
    .shortList {
        width: 8%;

        @media (max-width: $tablet) {
            width: 5%;
        }

        .dataContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            p {
                font-size: 14px;
            }

            img {
                filter: invert(54%) sepia(25%) saturate(811%) hue-rotate(93deg) brightness(96%) contrast(89%);
            }
        }
    }
}

.emptyRow {
    height: 10px;
}
