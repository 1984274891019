@import 'src/styles/variables';

$perspective: 250rem;
//$scale: 0.75; //0.5;

.sceneBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 50px);
    border: 1px solid $primary-500;
    border-radius: 4px;
    position: relative;
    perspective: $perspective;
    overflow: hidden;
    background-color: $light-200;
    //overscroll-behavior: none;
}

.scene {
    position: relative;
    transform-style: preserve-3d;

    @media (max-width: $xl) {
        transform-style: unset;
        perspective: $perspective;
    }

}

.footballPitch {
    transform-style: preserve-3d;
    position: absolute;
    width: 44.75rem;
    height: 64.75rem;
    //width: calc(68em * $scale);
    //height: calc(105em * $scale);
    //transform: translate(-50%, -50%);
    background: {
        color: $primary-500;
        image: url("../../../assets/images/footballPitch.png");
    }
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.smooth {
        //transition: all 250ms;
    }

    .length {
        position: absolute;
        height: calc(100%);
        width: calc(100%);
        background-color: #e6edea;
        transform: translateZ(-10px);
    }

    .pitchLines {
        user-select: none;
        pointer-events: none;
    }

}

.positionBox {
    transform-style: preserve-3d;
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    color: $primary-300;
    font-weight: 600;
    border: 2px solid $primary-300;
    background-color: $primary-600;
    box-shadow: 0 0 20px 0 rgba(21, 75, 43, 0.20);
    transform: translate(0, -100%);

    .position {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        user-select: none;

        .positionButton {
            color: $primary-300;
            font-family: 'Open Sans', sans-serif;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            outline: none;
            border: none;
            font-size: 1rem;
            font-weight: 700;
            cursor: pointer;
        }

        &Layer {
            user-select: none;
            position: absolute;
            top: -15px;
            display: flex;
            transform: translateY(-100%);

            &.below {
                top: 95px;
            }
        }
    }
}

.hud {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    user-select: none;

    // dev-tools easier usability
    pointer-events: none;
    & > * {
        pointer-events: auto;
    }

    @media (max-width: $xl) {
        padding: 25px;

        &.fullScreen {
            padding: 12px 15px;
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;

        // dev-tools easier usability
        pointer-events: none;
        & > * {
            pointer-events: auto;
        }

        .topRight {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            z-index: 100;

            @media (max-width: $xl) {
                gap: 25px;
            }
        }

        .toggleExpand {
            box-shadow: 2px 2px 5px -2px rgba(21, 75, 43, 0.42);

            img {
                width: 18px;
                height: 18px;
            }

            &.opened {
                transform: rotateX(180deg) rotateY(180deg);
            }
        }

        .views {
            flex-basis: 100%;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        .viewSelect {
            border-radius: 10px;
            padding: 5px;
            transform-style: preserve-3d;
            perspective: 250px;
            background-color: rgba(200, 228, 207, 0.75);
            box-shadow: 2px 2px 5px -2px rgba(21, 75, 43, 0.42);

            &.to3d {
                img {
                    transform: translateZ(30px) rotateX(45deg);
                }
            }

            img {
                width: 48px;
                height: 48px;
            }
        }
    }
}

.axisBox {
    // dev function
    display: none;
    width: 10rem;
    height: 10rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: 1px solid crimson;
    transform-style: preserve-3d;

    .direction {
        transform-style: preserve-3d;
        border-bottom: 3px solid black;
        transform: translate(50%, 5rem);
        &:after {
            position: absolute;
            content: '';
            width: 3px;
            height: 3px;
            background-color: chartreuse;
            right: 0;
        }

        &.directionX {
            border-color: red;
            &:after {
                content: 'X';
            }
        }
        &.directionY {
            transform: translateY(10rem) rotateZ(90deg);
            border-color: green; // top?
            &:after {
                content: 'Y';
                transform: rotateX(180deg);
            }
        }
        &.directionZ {
            transform: rotateY(-90deg) translateX(5rem) translateY(5rem);
            border-color: blue; // front?
            &:after {
                content: 'Z';
                transform: rotateX(-45deg) rotateY(275deg);
            }
        }
    }
}
