@import 'src/styles/variables';

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($light-100, 0.5);

  &.radius {
    border-radius: 4px;
  }

  &.dark {
    background-color: rgba($dark-100, 0.4);
  }

  svg {
    path {
      transition: all 300ms;
    }
  }
}
